<template>
  <div
    class="
      absolute
      z-40
      bg-white
      rounded-full
      shadow-md
      overflow-hidden
      transition-all
      flex
    "
  >
    <slot></slot>
  </div>
</template>
