
import { Options, Vue } from "vue-class-component";
import NrasSessionApi, { MediaType } from "../../utils/NrasSessionApi";

interface RTCIceCandidateInit {
  candidate?: string;
  sdpMLineIndex?: number | null;
  sdpMid?: string | null;
  usernameFragment?: string | null;
}

@Options({
  props: {
    sessionApi: NrasSessionApi,
    userId: String,
    outputDeviceId: String,
    audioMuted: Boolean,
  },
  computed: {
    peerConfiguration() {
      const peerConfig = this.$store.getters.getPeerConfiguration;
      return {
        iceServers: [
          {
            urls: [peerConfig.stunUrl],
          },
          {
            urls: [peerConfig.turnUrl],
            username: peerConfig.turnUser,
            credential: peerConfig.turnPass,
          },
        ],
      };
    },
  },
  data: function () {
    return {
      audioRtcPeer: null,
      onConsumeAudioResponseHandler: null,
      onIceCandidateHandler: null,
      audioElement: HTMLAudioElement,
      playTimeout: undefined,
      retries: 0,
    };
  },
  emits: ["confirmAudio"],
  watch: {
    outputDeviceId(_oldOutputDeviceId, newOutputDeviceId) {
      if (newOutputDeviceId) {
        this.audioElement
          .setSinkId(newOutputDeviceId)
          .then(() => console.log("Changed output device", newOutputDeviceId))
          .catch((error: unknown) =>
            console.error("Failed to change output device", error)
          );
      }
    },
    audioMuted(_oldMutedValue, newMutedValue) {
      if (!newMutedValue) {
        // const audio = this.$refs["audio"];
        if (this.audioElement.paused) {
          this.autoPlayAudio();
        }
      }
    },
  },
  mounted() {
    if (this.userId && this.sessionApi && !this.audioRtcPeer) {
      this.connect();
    }
  },
  beforeUnmount() {
    this.dispose();
  },
  methods: {
    async connect() {
      console.log("AudioStream connect", this.userId);
      this.audioElement = this.$refs["audio"];
      console.log("audio element", this.audioElement);

      let receiveAudioIceQueue: RTCIceCandidateInit[] = [];
      let sendAudioIceQueue: RTCIceCandidate[] = [];

      let consumeAudioResponse = false;

      this.audioElement.volume = 1;

      this.onConsumeAudioResponseHandler = (
        conferenceUserId: string,
        sdpAnswer: string
      ) => {
        if (conferenceUserId === this.userId) {
          console.log(`Received SDP answer for ${this.userId}`, sdpAnswer);
          if (sdpAnswer) {
            consumeAudioResponse = true;
            this.audioRtcPeer
              .setRemoteDescription({ type: "answer", sdp: sdpAnswer })
              .then(() => {
                receiveAudioIceQueue.forEach((candidate: RTCIceCandidateInit) =>
                  this.audioRtcPeer.addIceCandidate(
                    new RTCIceCandidate(candidate)
                  )
                );
                receiveAudioIceQueue = [];
                sendAudioIceQueue.forEach((item: RTCIceCandidateInit) => {
                  let { candidate, sdpMid, sdpMLineIndex } = item;
                  this.sessionApi.sendIceCandidate(
                    "AUDIO",
                    candidate,
                    sdpMid,
                    sdpMLineIndex,
                    this.userId
                  );
                  sendAudioIceQueue = [];
                  console.log(`Sent AUDIO ICE candidate for ${this.userId}`);
                });
              })
              .catch((error: unknown) => console.error(error));
          }
        }
      };
      this.sessionApi.onConsumeAudioResponse(
        this.onConsumeAudioResponseHandler
      );

      this.onIceCandidateHandler = (
        mediaType: MediaType,
        candidate: string,
        sdpMid: string,
        sdpMLineIndex: number,
        conferenceUserId: string
      ) => {
        if (mediaType === "AUDIO" && conferenceUserId === this.userId) {
          if (this.audioRtcPeer.remoteDescription) {
            this.audioRtcPeer.addIceCandidate({
              candidate,
              sdpMid,
              sdpMLineIndex,
            });
          } else {
            receiveAudioIceQueue.push({ candidate, sdpMid, sdpMLineIndex });
          }
          console.log(
            `${conferenceUserId} ${mediaType} ICE candidate received`
          );
        }
      };
      this.sessionApi.onIceCandidate(this.onIceCandidateHandler);

      this.audioRtcPeer = new RTCPeerConnection(this.peerConfiguration);
      await this.audioRtcPeer.addTransceiver("audio", {
        direction: "recvonly",
        send: false,
        receive: true,
      });

      this.audioRtcPeer.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        if (event.candidate) {
          if (!consumeAudioResponse) {
            sendAudioIceQueue.push(event.candidate);
          } else {
            let { candidate, sdpMid, sdpMLineIndex } = event.candidate;
            this.sessionApi.sendIceCandidate(
              "AUDIO",
              candidate,
              sdpMid,
              sdpMLineIndex,
              this.userId
            );
            console.log(`Sent AUDIO ICE candidate for ${this.userId}`);
          }
        }
      };

      this.audioRtcPeer.ontrack = (event: RTCTrackEvent) => {
        let track = event.track;
        console.log("AUDIO remote track", track.label);
        this.audioElement.srcObject = event.streams[0];
        track.onmute = () => console.log(`AUDIO track ${track.label} muted`);
        track.onunmute = () =>
          console.log(`AUDIO track ${track.label} unmuted`);
      };

      let audioOffer = await this.audioRtcPeer.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: false,
      });

      await this.audioRtcPeer.setLocalDescription(audioOffer);

      console.log("-----------------------------------");
      console.log(`AUDIO OFFER SDP for ${this.userId}`, audioOffer.sdp);
      console.log(
        `AUDIO OFFER localDescription for ${this.userId}`,
        this.audioRtcPeer.localDescription.sdp
      );
      console.log("-----------------------------------");

      await this.sessionApi.consumeAudio(
        this.userId,
        this.audioRtcPeer.localDescription.sdp
      );

      await this.autoPlayAudio();

      if (this.audioMuted) {
        this.audioElement.muted = true;
      }
    },

    async autoPlayAudio() {
      this.audioElement.play().then(
        () => {
          if (this.retries !== 0) {
            // success after some failures
            this.playTimeout = undefined;
            console.log(
              "Successfully played after " + this.retries + " retries"
            );
          } else {
            console.log("Successfully playing audio, FIRST TIME");
          }
        },
        (error: Error) => {
          console.log(
            "Failed to play audio after " + this.retries + " retries",
            error
          );

          if (this.retries < 3) {
            this.playTimeout = setTimeout(() => {
              this.retries++;
              this.autoPlayAudio();
            }, 2500);

            if (this.retries === 0) {
              console.log("First retry error message, should only see once");
            }
          } else {
            // Lets get user to interact with the document and try again
            console.log(
              "Over 3 attempts to connect to audio, getting user to interact with the document and trying again after 5 seconds"
            );

            this.playTimeout = setTimeout(() => {
              this.$store.dispatch("audioMuted", true);
              this.$emit("confirmAudio");
              this.playTimeout = undefined;
              this.retries = 0;
            }, 5000);
          }
        }
      );

      // this.audioElement.muted = false;

      // try {
      //   if (this.audioElement.paused) {
      //     await this.audioElement.play();
      //   }
      //   console.log("Audio play successful");
      // } catch (error) {
      //   alert(error);
      //   console.log("Unable to play the audio", error);
      //   this.$store.dispatch("audioMuted", true);
      //   this.$emit("confirmAudio");
      // }
    },
    async dispose() {
      await this.sessionApi.stopConsumeAudio(this.userId);
      this.sessionApi.removeOnIceCandidateHandler(this.onIceCandidateHandler);
      this.sessionApi.removeConsumeAudioResponseHandler(
        this.onConsumeAudioResponseHandler
      );

      // const audio = this.$refs["audio"];
      if (this.audioElement) {
        this.audioElement.srcObject = null;
      }
      this.audioRtcPeer?.close();
      //this.audioRtcPeer?.dispose();
      this.audioRtcPeer = null;

      if (this.playTimeout) {
        clearTimeout(this.playTimeout);
        this.playTimeout = undefined;
      }
    },
  },
})
export default class VideoStream extends Vue {}
