
import { Options, Vue } from "vue-class-component";
import NrasSessionApi, {
  MediaType,
  UserState,
} from "../../utils/NrasSessionApi";
import AudioStream from "@/components/utils/AudioStream.vue";
import ModalDeviceSelection from "@/components/RemoteClient/ModalDeviceSelection.vue";
import TelestrationCanvas from "@/components/utils/TelestrationCanvas.vue";
import ModalConfirmation from "@/components/global/ModalConfirmation.vue";
import router from "@/router";
import { PropType } from "@vue/runtime-core";

enum ConnectionState {
  Connecting,
  Connected,
  Disconnected,
  Failed,
  Unknown,
}

interface RTCIceCandidateInit {
  candidate?: string;
  sdpMLineIndex?: number | null;
  sdpMid?: string | null;
  usernameFragment?: string | null;
}

@Options({
  components: {
    AudioStream,
    ModalDeviceSelection,
    TelestrationCanvas,
    ModalConfirmation,
  },
  props: {
    sessionApi: NrasSessionApi,
    inviteId: String,
    sessionId: String,
    users: Array as PropType<UserState[]>,
    liveBroadcastId: String,
    liveTelestrationId: String,
    showAudioControls: Boolean,
  },
  emits: ["closeSession", "videoReconnect"],
  computed: {
    held() {
      return this.$store.state.session.telestration.held;
    },
    peerConfiguration() {
      const peerConfig = this.$store.getters.getPeerConfiguration;
      return {
        iceServers: [
          {
            urls: [peerConfig.stunUrl],
          },
          {
            urls: [peerConfig.turnUrl],
            username: peerConfig.turnUser,
            credential: peerConfig.turnPass,
          },
        ],
      };
    },
    isTouchDevice() {
      return this.$store.state.session.isTouchDevice;
    },
    audioMuted() {
      return this.$store.state.session.audioMuted;
    },
    micMuted() {
      return this.$store.state.session.micMuted;
    },
    audioUsers() {
      return this.users.filter(
        (user: UserState) => !user.self && user.hasAudio
      );
    },
    inputDeviceId() {
      return this.$store.state.session.inputDeviceId;
    },
    inputDeviceAvailable() {
      return this.$store.state.session.inputDeviceAvailable;
    },
    outputDeviceId() {
      return this.$store.state.session.outputDeviceId;
    },
    outputDeviceAvailable() {
      return this.$store.state.session.outputDeviceAvailable;
    },
  },
  data: function () {
    return {
      video: null,
      audio: null,
      videoRtcPeer: null,
      audioRtcPeer: null,
      audioSender: null,
      mediaStream: null,
      mediaDevices: null,
      connectionState: ConnectionState.Unknown,
      showDevicesModal: false,
      showLogs: false,
      maxHeight: null,
      confirm: undefined,
      countFramesDecoded: 0,
      connecting: false,
      decodeInterval: null,
      setDeviceIds: ["default", "communications"],
      waitForNextUserStateChanged: true,
    };
  },
  updated() {
    if (this.inviteId && this.sessionApi && !this.videoRtcPeer) {
      this.connect();
    }
  },
  watch: {
    audioMuted() {
      this.sendSessionStateUpdate();
    },
    micMuted() {
      this.sendSessionStateUpdate();
    },
  },
  mounted() {
    this.getVideoAspect();
    const callback = (entries: ResizeObserverEntry[]) => {
      if (entries.length > 0) {
        const width = entries[0].contentRect.width;
        const height = entries[0].contentRect.height;
        this.getVideoAspect(width, height);
      }
    };
    const videoConstraints = this.$refs.videoConstraints;
    if (videoConstraints) {
      new ResizeObserver(callback).observe(videoConstraints);
    }

    navigator.mediaDevices.ondevicechange = (event) => {
      // console.log("media device change", event);

      this.updateDeviceList();
    };
  },
  beforeUnmount() {
    this.dispose();
    // clearInterval(this.decodeInterval);
  },
  methods: {
    getTelestrationCanvas() {
      return this.$refs.telestrationCanvas;
    },
    getVideoAspect(width: number, height: number) {
      // Working out video resizing
      let aspect = (1080 / 1920) * width;

      if (aspect > height) {
        this.maxHeight = true;
      } else {
        this.maxHeight = false;
      }
    },
    openDevicesModal() {
      this.showDevicesModal = true;
    },
    playVideo() {
      this.video.play();
    },
    muteVideo() {
      this.video.muted = true;
    },
    muteAudio() {
      // Mute speakers also mutes microphone
      if (!this.micMuted) {
        this.muteMic();
      }
      this.$store.dispatch("audioMuted", true);
    },
    unMuteAudio() {
      // Unmute mic if not manually muted
      if (!this.micMuted) {
        this.unMuteMic();
      }
      this.$store.dispatch("audioMuted", false);
    },
    muteMic() {
      this.mediaStream
        .getAudioTracks()
        .forEach(function (track: { enabled: boolean }) {
          track.enabled = false;
        });
    },
    unMuteMic() {
      this.waitForNextUserStateChanged = true;
      this.mediaStream
        .getAudioTracks()
        .forEach(function (track: { enabled: boolean }) {
          console.log("un muting");

          track.enabled = true;
        });
    },
    toggleMic() {
      if (!this.audioMuted) {
        const muted = !this.mediaStream.getAudioTracks()[0].enabled;
        if (muted) {
          this.unMuteMic();
        } else {
          this.muteMic();
        }
        this.$store.dispatch("micMuted", !this.micMuted);
      }
    },
    toggleAudio() {
      if (this.audioMuted) {
        this.unMuteAudio();
      } else {
        this.muteAudio();
      }
    },
    sendSessionStateUpdate() {
      this.sessionApi.updateConferenceState(
        !this.audioMuted,
        !this.micMuted && !this.audioMuted
      );
    },
    async connect() {
      this.video = document.getElementById("video");

      // eslint-disable-next-line @typescript-eslint/no-explicit-any

      // TODO - Check this
      // this.audio.addEventListener("volumechange", (audio: any) => {
      //   console.log("Audio volume changed", audio.target.muted);
      //   this.audioMuted = audio.target.muted;
      // });

      let videoIceQueue: RTCIceCandidateInit[] = [];
      let audioIceQueue: RTCIceCandidateInit[] = [];

      // console.log("audioMuted", this.audioMuted);

      // Make sure muted
      this.video.muted = true;

      this.sessionApi.onClientResponse(
        (sdpAnswers: { video: string; audio: string }) => {
          console.log("Received SDP answer");
          this.videoRtcPeer
            .setRemoteDescription({ type: "answer", sdp: sdpAnswers.video })
            .then(() => {
              videoIceQueue.forEach((candidate: RTCIceCandidateInit) =>
                this.videoRtcPeer.addIceCandidate(
                  new RTCIceCandidate(candidate)
                )
              );
            })
            .catch((error: unknown) => console.error(error));
          this.audioRtcPeer
            .setRemoteDescription({ type: "answer", sdp: sdpAnswers.audio })
            .then(() => {
              audioIceQueue.forEach((candidate: RTCIceCandidateInit) =>
                this.audioRtcPeer.addIceCandidate(
                  new RTCIceCandidate(candidate)
                )
              );
            })
            .catch((error: unknown) => console.error(error));
          this.sendSessionStateUpdate();
        }
      );

      this.sessionApi.onIceCandidate(
        (
          mediaType: MediaType,
          candidate: string,
          sdpMid: string,
          sdpMLineIndex: number,
          conferenceUserId: string
        ) => {
          // If there is a conferenceUserId then this is for incoming audio and not handled here
          if (!conferenceUserId) {
            const peer =
              mediaType === "VIDEO" ? this.videoRtcPeer : this.audioRtcPeer;
            const queue = mediaType === "VIDEO" ? videoIceQueue : audioIceQueue;
            if (peer.remoteDescription) {
              peer.addIceCandidate({
                candidate,
                sdpMid,
                sdpMLineIndex,
              });
            } else {
              queue.push({ candidate, sdpMid, sdpMLineIndex });
            }
            console.log(`${mediaType} ICE candidate received`);
          }
        }
      );

      this.videoRtcPeer = new RTCPeerConnection(this.peerConfiguration);
      await this.videoRtcPeer.addTransceiver("video", {
        direction: "recvonly",
        send: false,
        receive: true,
      });

      this.audioRtcPeer = new RTCPeerConnection(this.peerConfiguration);

      console.log("videoRtcPeer", this.videoRtcPeer);

      // setInterval(async () => {
      //   let stats: RTCStatsReport = await this.webRtcPeer.getStats();
      //   stats.forEach((value: unknown, key: string) => {
      //     console.log(key, value);
      //   });
      // }, 5000);

      this.videoRtcPeer.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        console.log("videoRtcPeer onicecandidate", event);
        if (event.candidate) {
          let { candidate, sdpMid, sdpMLineIndex } = event.candidate;
          this.sessionApi.sendIceCandidate(
            "VIDEO",
            candidate,
            sdpMid,
            sdpMLineIndex
          );
          console.log("Sent VIDEO ICE candidate");
        }
      };

      this.videoRtcPeer.ontrack = (event: RTCTrackEvent) => {
        console.log("on track video", event);
        let track = event.track;
        console.log("VIDEO remote track", track.label);

        const stream = event.streams[0];
        // Strip audio tracks from video stream
        for (const audioTrack of stream.getAudioTracks()) {
          audioTrack.stop();
          stream.removeTrack(audioTrack);
        }

        this.video.srcObject = stream;

        track.onmute = () => console.log(`VIDEO track ${track.label} muted`);
        track.onunmute = () =>
          console.log(`VIDEO track ${track.label} unmuted`);
        track.onended = (event) => {
          console.log("Track ended", event);
        };
      };

      this.audioRtcPeer.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        if (event.candidate) {
          let { candidate, sdpMid, sdpMLineIndex } = event.candidate;
          this.sessionApi.sendIceCandidate(
            "AUDIO",
            candidate,
            sdpMid,
            sdpMLineIndex
          );
          console.log("Sent AUDIO ICE candidate");
        }
      };

      this.videoRtcPeer.onconnectionstatechange = () => {
        console.log(
          "video connection state",
          this.videoRtcPeer.connectionState
        );

        const clearIntervalDecode = setInterval(() => {
          // if (this.videoRtcPeer.getReceivers()) {
          this.videoRtcPeer
            .getReceivers()
            .forEach((receiver: RTCRtpReceiver) => {
              if (receiver.track.kind == "video") {
                receiver.getStats().then((myStatsReport: RTCStatsReport) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  myStatsReport.forEach((statValue: any) => {
                    if (statValue.type == "inbound-rtp") {
                      console.log(JSON.stringify(statValue));
                      console.log("Frames decoded", statValue.framesDecoded);
                      if (
                        statValue.framesDecoded < 1 &&
                        statValue.framesReceived > 0
                      ) {
                        this.countFramesDecoded++;
                        this.connecting = true;
                      } else {
                        this.connecting = false;
                        this.countFramesDecoded = 0;
                        // this.clearDecodeInterval();
                        clearInterval(clearIntervalDecode);
                      }
                      // console.log(
                      //   "Frames count decoded",
                      //   this.countFramesDecoded
                      // );
                      if (this.countFramesDecoded >= 5) {
                        this.countFramesDecoded = 0;
                        this.connecting = false;
                        clearInterval(clearIntervalDecode);
                        this.reconnect();
                      }
                    }
                  });
                });
              }
            });
          // } else {
          //   this.countFramesDecoded++;
          //   this.connecting = true;
          //   console.log("no receiver count", this.countFramesDecoded);
          //   if (this.countFramesDecoded >= 5) {
          //     this.reconnect();
          //     this.countFramesDecoded = 0;
          //     this.connecting = false;
          //   }
          // }
        }, 5000);

        switch (this.videoRtcPeer.connectionState) {
          case "new":
          case "checking":
            this.connectionState = ConnectionState.Connecting;
            break;

          case "connected":
            this.connectionState = ConnectionState.Connected;
            break;

          case "disconnected":
          case "closed":
            this.connectionState = ConnectionState.Disconnected;
            break;

          case "failed":
            this.connectionState = ConnectionState.Failed;
            break;

          default:
            this.connectionState = ConnectionState.Unknown;
            break;
        }

        console.log("Connection state changed", this.connectionState);
      };

      this.startAudio().then(async () => {
        let videoOffer = await this.videoRtcPeer.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        });

        let audioOffer = await this.audioRtcPeer.createOffer({
          offerToReceiveAudio: false,
          offerToReceiveVideo: false,
        });

        await this.videoRtcPeer.setLocalDescription(videoOffer);
        await this.audioRtcPeer.setLocalDescription(audioOffer);

        await this.sessionApi.sendClientRequest(
          this.sessionId,
          this.inviteId,
          // videoOffer.sdp,npm run ser
          // audioOffer.sdp
          this.videoRtcPeer.localDescription.sdp,
          this.audioRtcPeer.localDescription.sdp
        );

        // Wait until we have sent the first audio state update before listening to server side mute
        setTimeout(() => {
          this.waitForNextUserStateChanged = false;
        }, 5000);
        this.sessionApi.onUserStateChanged(
          (
            _userId: string,
            self: boolean,
            _hasAudio: boolean,
            speakerActive: boolean,
            microphoneActive: boolean
          ) => {
            if (self) {
              console.log(
                `Audio state change from server: speakerActive=${speakerActive} micActive=${microphoneActive}`
              );
            }
            if (self && speakerActive && microphoneActive) {
              this.waitForNextUserStateChanged = false;
            }
            if (
              self &&
              !this.audioMuted &&
              !this.micMuted &&
              !microphoneActive &&
              !this.waitForNextUserStateChanged
            ) {
              // Our microphone has been muted by someone else
              console.log("Microphone muted by server");
              this.muteMic();
              this.$store.dispatch("micMuted", !this.micMuted);
            }
          }
        );

        console.log("-----------------------------------");
        console.log("VIDEO OFFER SDP", videoOffer.sdp);
        console.log(
          "VIDEO OFFER localDescription",
          this.videoRtcPeer.localDescription.sdp
        );
        console.log("-----------------------------------");
      });
    },
    updateDeviceList() {
      navigator.mediaDevices.enumerateDevices().then(async (mediaDevices) => {
        // Update new device list
        // this.mediaDevices = mediaDevices.filter(
        //   (deviceInfo: MediaDeviceInfo) =>
        //     !this.setDeviceIds.includes(deviceInfo.deviceId)
        // );
        this.mediaDevices = mediaDevices;
        console.log("Media devices ", this.mediaDevices);

        // Check if input or output is still available
        const activeInputDevice = this.mediaDevices.find(
          (deviceInfo: MediaDeviceInfo) =>
            deviceInfo.kind === "audioinput" &&
            deviceInfo.deviceId === this.inputDeviceId
        );

        // console.log("is input device active", activeInputDevice);

        // If undefined set to null
        if (activeInputDevice === undefined) {
          // If unplugged input
          this.$store.dispatch("setInputDeviceID", null);
          // Set a new input device
          await this.startAudio();
        } else if (!this.inputDeviceId) {
          // If plugged in input and none set
          this.startAudio();
        }

        const activeOutputDevice = this.mediaDevices.find(
          (deviceInfo: MediaDeviceInfo) =>
            deviceInfo.kind === "audiooutput" &&
            deviceInfo.deviceId === this.outputDeviceId
        );

        // console.log("is output device active", activeOutputDevice);

        const setNextOutput = async () => {
          const nextDevice = mediaDevices.find(
            (deviceInfo: MediaDeviceInfo) => deviceInfo.kind === "audiooutput"
          );
          // Try to set next available output if available
          if (nextDevice) {
            await this.selectDevices("onlyOutput", nextDevice.deviceId);
          } else {
            this.$store.dispatch("setOutputDeviceAvailable", false);
          }
        };

        // If undefined set to null
        if (activeOutputDevice === undefined) {
          // If unplugged output
          this.$store.dispatch("setOutputDeviceID", null);
          await setNextOutput();
        } else if (!this.outputDeviceId) {
          // If plugged in output and none set
          // Not working
          await setNextOutput();
        }
      });
    },
    onMediaStream(mediaStream: MediaStream) {
      let audioRtcPeer = this.audioRtcPeer as RTCPeerConnection;
      mediaStream.getAudioTracks().forEach((track: MediaStreamTrack) => {
        if (!this.mediaStream) {
          this.audioSender = audioRtcPeer.addTrack(track, mediaStream);
        } else {
          this.audioSender.replaceTrack(track);
        }
        console.log(
          "Changed input device",
          track.label,
          track.enabled,
          track.muted,
          track.readyState
        );
      });
      this.mediaStream = mediaStream;
      return navigator.mediaDevices.enumerateDevices();
    },
    async onEnumeratedDevices(mediaDevices: MediaDeviceInfo[]) {
      this.mediaDevices = mediaDevices;

      console.log("MEDIA DEVICES", this.mediaDevices);

      // Get just input devices
      const allInputDevices = mediaDevices.filter(
        (deviceInfo: MediaDeviceInfo) => deviceInfo.kind === "audioinput"
      );

      // Audio Input (Microphone)
      // If no device selected
      if (!this.inputDeviceId) {
        const communicationsDevice = allInputDevices.find(
          (deviceInfo: MediaDeviceInfo) =>
            deviceInfo.deviceId === "communications"
        );
        const defaultDevice = allInputDevices.find(
          (deviceInfo: MediaDeviceInfo) => deviceInfo.deviceId === "default"
        );
        // By default select 'communications' device
        if (communicationsDevice) {
          this.$store.dispatch("setInputDeviceID", "communications");
          this.$store.dispatch("setInputDeviceAvailable", true);
        } else if (defaultDevice) {
          this.$store.dispatch("setInputDeviceID", "default");
          this.$store.dispatch("setInputDeviceAvailable", true);
        } else if (allInputDevices[0]) {
          // Just select first in list if available
          this.$store.dispatch(
            "setInputDeviceID",
            allInputDevices[0]?.deviceId
          );
          this.$store.dispatch("setInputDeviceAvailable", true);
        } else {
          // None available so maybe warn the user they cant talk
          this.$store.dispatch("setInputDeviceID", null);
          this.$store.dispatch("setInputDeviceAvailable", false);
        }
      } else {
        this.$store.dispatch("setInputDeviceAvailable", true);
      }

      // Get just output devices
      const allOutputDevices = mediaDevices.filter(
        (deviceInfo: MediaDeviceInfo) => deviceInfo.kind === "audiooutput"
      );

      // Audio Output (Speaker)
      // If no device selected
      if (!this.outputDeviceId) {
        const communicationsDevice = allOutputDevices.find(
          (deviceInfo: MediaDeviceInfo) =>
            deviceInfo.deviceId === "communications"
        );
        const defaultDevice = allOutputDevices.find(
          (deviceInfo: MediaDeviceInfo) => deviceInfo.deviceId === "default"
        );
        // By default select 'communications' device
        if (communicationsDevice) {
          this.$store.dispatch("setOutputDeviceID", "communications");
          this.$store.dispatch("setOutputDeviceAvailable", true);
        } else if (defaultDevice) {
          this.$store.dispatch("setOutputDeviceID", "default");
          this.$store.dispatch("setOutputDeviceAvailable", true);
        } else if (allOutputDevices[0]) {
          // Just select first in list if available
          this.$store.dispatch(
            "setOutputDeviceID",
            allOutputDevices[0]?.deviceId
          );
          this.$store.dispatch("setOutputDeviceAvailable", true);
        } else {
          // None available so maybe warn the user they cant listen
          this.$store.dispatch("setOutputDeviceID", null);
          this.$store.dispatch("setOutputDeviceAvailable", false);
        }
      } else {
        this.$store.dispatch("setOutputDeviceAvailable", true);
      }

      // Make sure the mic is muted if mute flag is on
      if (this.micMuted || this.audioMuted) this.muteMic();
    },
    async startAudio() {
      // Free the mics as Firefox won't let you call getUserMedia while you're already using one
      // See https://bugzilla.mozilla.org/show_bug.cgi?id=1238038
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      }

      const constraints = {
        video: false,
        audio: {
          deviceId: this.inputDeviceId
            ? { exact: this.inputDeviceId }
            : undefined,
        },
      };

      console.log("INPUT DEVICE", this.inputDeviceId);

      return navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.onMediaStream)
        .then(this.onEnumeratedDevices)
        .catch((error: unknown) => {
          console.error("failed to set media devices", error);
          // return navigator.mediaDevices;
        });
    },
    async selectDevices(newInputDeviceId: string, newOutputDeviceId: string) {
      if (
        this.inputDeviceId != newInputDeviceId &&
        newInputDeviceId !== "onlyOutput"
      ) {
        this.$store.dispatch("setInputDeviceID", newInputDeviceId);
        await this.startAudio();
      }

      if (this.outputDeviceId != newOutputDeviceId) {
        this.$store.dispatch("setOutputDeviceID", newOutputDeviceId);
      }
    },
    async dispose() {
      // console.log("this.videoRtcPeer", this.videoRtcPeer);
      // console.log("this.audioRtcPeer", this.audioRtcPeer);

      if (this.mediaStream) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.mediaStream.getTracks().forEach((track: any) => {
          track.enabled = false;
          track.stop();
        });
      }

      if (this.video) {
        this.video.srcObject = null;
      }
      if (this.audio) {
        this.audio.srcObject = null;
      }

      this.videoRtcPeer?.close();
      //this.videoRtcPeer?.dispose();
      this.videoRtcPeer = null;

      this.audioRtcPeer?.close();
      //this.audioRtcPeer?.dispose();
      this.audioRtcPeer = null;

      this.$emit("closeSession");

      // console.log("this.videoRtcPeer", this.videoRtcPeer);
      // console.log("this.audioRtcPeer", this.audioRtcPeer);
    },

    async reconnect() {
      // Attempting to reconnect here
      // this.$store.dispatch("Reconnect");
      this.$emit("videoReconnect");
      // this.dispose(false, true);
      // this.connect();
    },

    confirmAudio() {
      this.confirm = {
        text: this.$t("pages.remoteSession.allowAudio"),
        yes: () => {
          this.unMuteAudio();
          this.confirm = undefined;
        },
        no: () => {
          this.confirm = undefined;
        },
      };
    },
    confirmExit() {
      this.confirm = {
        text: this.$t("pages.remoteSession.confirmExit"),
        yes: () => {
          this.confirm = undefined;
          this.menuActive = "";
          router.push({
            name: "RemoteClientEnded",
            query: { reason: "USER_LEFT" },
          });
        },
        no: () => {
          this.confirm = undefined;
          this.menuActive = "";
        },
      };
    },
    clearDecodeInterval() {
      console.log("Clear interval");
      clearInterval(this.decodeInterval);
    },
  },
})
export default class VideoStream extends Vue {}
