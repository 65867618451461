
import { Options, Vue } from "vue-class-component";
import NrasSessionApi from "../../utils/NrasSessionApi";
import ButtonCircle from "@/components/utils/ButtonCircle.vue";
import DropdownTelestration from "@/components/utils/DropdownTelestration.vue";
import ModalConfirmation from "@/components/global/ModalConfirmation.vue";

import { uuid } from "vue-uuid";

const SVG_NS = "http://www.w3.org/2000/svg";
const TEXT_Y_OFFSET = 86;
const UNSELECTABLE_ELEMENT_IDS = ["svg", "holdImage", "grid"];

@Options({
  components: {
    ButtonCircle,
    DropdownTelestration,
    ModalConfirmation,
  },
  props: {
    sessionApi: NrasSessionApi,
    liveTelestrationId: String,
  },
  data() {
    return {
      svg: undefined,
      canvasWidth: 0,
      canvasHeight: 0,
      started: false,
      startX: 0,
      startY: 0,
      moveX: 0,
      moveY: 0,
      endX: 0,
      endY: 0,
      textX: 0,
      textY: 0,
      video: null,
      rectHeight: 0,
      rectWidth: 0,
      path: [],
      lastPathPoint: null,
      textEditing: false,
      textColour: undefined,
      textElementValue: "",
      previousTextValue: "",
      updatingExistingText: false,
      currentId: "",
      sentShapeIds: [],
      updateTimeout: false,
      updateTimeoutTime: 250,
      hoveredElement: undefined,
    };
  },
  computed: {
    textBoxPos() {
      return this.fromSvgPoint(this.textX, this.textY);
    },
    shapeType() {
      return this.$store.state.session.telestration.shapeType;
    },
    strokeWidth() {
      return this.$store.state.session.telestration.strokeWidth;
    },
    activeColour() {
      return this.$store.state.session.telestration.activeColour;
    },
    eraseMode() {
      return this.$store.state.session.telestration.eraseMode;
    },
    gridActive() {
      return this.$store.state.session.telestration.gridActive;
    },
    telestrationMobileActive() {
      return this.$store.state.session.telestrationMobileActive;
    },
  },
  mounted() {
    this.svg = document.getElementById("svg");

    const canvas = document.getElementById("canvas");
    const callback = (entries: ResizeObserverEntry[]) => {
      if (this.textEditing) {
        this.endTextEdit();
      }
      if (entries.length > 0) {
        this.canvasWidth = entries[0].contentRect.width;
        this.canvasHeight = entries[0].contentRect.height;
      }
    };
    if (canvas) {
      new ResizeObserver(callback).observe(canvas);
    }
  },
  watch: {
    textElementValue(newValue) {
      // Restrict text input to only letters, numbers and spaces
      this.textElementValue = newValue.replace(/[^A-Za-z0-9 ]/g, "");
    },
  },
  methods: {
    generateUniqueId() {
      return uuid.v4();
    },
    mouseDown(event: MouseEvent) {
      this.start(event.clientX, event.clientY, event.offsetX, event.offsetY);
    },
    touchStart(event: TouchEvent) {
      if (this.telestrationMobileActive) {
        event.preventDefault();
        event.stopPropagation();
        if (event.touches?.length === 1) {
          const touch = event.touches[0];
          var rect = this.svg.getBoundingClientRect();
          this.start(
            touch.clientX,
            touch.clientY,
            touch.clientX - rect.left,
            touch.clientY - rect.top
          );
        }
      }
    },
    start(clientX: number, clientY: number, x: number, y: number) {
      if (this.textEditing) {
        this.endTextEdit();
        return;
      }
      const elementAtPoint: SVGElement = this.getElementAt(clientX, clientY);
      if (elementAtPoint && elementAtPoint.nodeName === "text") {
        return;
      }
      if (!this.eraseMode && this.shapeType !== "text") {
        this.started = true;
        this.startX = x;
        this.startY = y;

        let newShape;

        if (this.shapeType.includes("rectangle")) {
          newShape = document.createElementNS(SVG_NS, "rect");
        }

        if (this.shapeType.includes("ellipse")) {
          newShape = document.createElementNS(SVG_NS, "ellipse");
        }

        if (this.shapeType === "path") {
          newShape = document.createElementNS(SVG_NS, "path");
        }

        if (newShape) {
          this.currentId = this.generateUniqueId();

          newShape.setAttribute("z-index", this.getZIndex());
          newShape.setAttribute("id", this.currentId);
          if (this.shapeType.includes("Fill")) {
            newShape.setAttribute("stroke", "none");
            newShape.setAttribute("stroke-width", "0");
            newShape.setAttribute("fill", this.activeColour);
          } else {
            newShape.setAttribute("stroke", this.activeColour);
            newShape.setAttribute("stroke-width", this.strokeWidth);
            newShape.setAttribute("fill", "none");
          }
          this.svg.append(newShape);

          this.sendShape(this.currentId, newShape);
        }
      }
    },
    touchMove(event: TouchEvent) {
      if (this.telestrationMobileActive) {
        event.preventDefault();
        event.stopPropagation();
        if (event.touches?.length === 1 && event.changedTouches?.length > 0) {
          const touch = event.changedTouches[0];
          var rect = this.svg.getBoundingClientRect();
          this.move(
            touch.clientX,
            touch.clientY,
            touch.clientX - rect.left,
            touch.clientY - rect.top
          );
        }
      }
    },
    mouseMove(event: MouseEvent) {
      this.move(event.clientX, event.clientY, event.offsetX, event.offsetY);
    },
    move(clientX: number, clientY: number, x: number, y: number) {
      if (!this.textEditing) {
        // Handle highlighting shapes on hover for Erase Mode
        const elementAtPoint = this.getElementAt(clientX, clientY);
        if (this.hoveredElement && this.hoveredElement !== elementAtPoint) {
          this.setHover(this.hoveredElement, false);
          this.hoveredElement = undefined;
        }
        if (
          elementAtPoint &&
          this.eraseMode &&
          this.hoveredElement !== elementAtPoint
        ) {
          this.setHover(elementAtPoint, true);
          this.hoveredElement = elementAtPoint;
        }

        if (this.started) {
          this.moveX = x;
          this.moveY = y;

          if (this.shapeType === "text") {
            return;
          }

          if (this.shapeType.includes("rectangle")) {
            this.createRectangle();
          }

          if (this.shapeType.includes("ellipse")) {
            this.createEllipse();
          }

          if (this.shapeType === "path") {
            this.createPath();
          }

          if (!this.updateTimeout) {
            const id = this.currentId;
            const shape = this.getSvgElementById(id);
            this.updateTimeout = true;
            this.sentShapeIds.push(id);
            setTimeout(() => {
              this.updateShape(id, shape);
            }, this.updateTimeoutTime);
          }
        }
      }
    },
    setHover(svgElement: SVGElement, hover: boolean) {
      if (hover) {
        const fill = svgElement.getAttribute("fill");
        if (fill && fill !== "none") {
          svgElement.style.stroke = fill;
        }
        svgElement.style.strokeWidth = "8";
      } else {
        svgElement.style.strokeWidth = "";
        svgElement.style.stroke = "";
      }
    },
    createRectangle() {
      const rect = this.getSvgElementById(this.currentId);
      if (rect) {
        const x1 = Math.min(this.startX, this.moveX),
          y1 = Math.min(this.startY, this.moveY),
          x2 = Math.max(this.startX, this.moveX),
          y2 = Math.max(this.startY, this.moveY);

        const svgPoint1 = this.toSvgPoint(x1, y1);
        const svgPoint2 = this.toSvgPoint(x2, y2);

        rect.setAttribute("x", svgPoint1.x);
        rect.setAttribute("y", svgPoint1.y);
        rect.setAttribute("width", svgPoint2.x - svgPoint1.x);
        rect.setAttribute("height", svgPoint2.y - svgPoint1.y);
      }
    },
    createEllipse() {
      const ellipse = this.getSvgElementById(this.currentId);
      if (ellipse) {
        const x1 = Math.min(this.startX, this.moveX),
          y1 = Math.min(this.startY, this.moveY),
          x2 = Math.max(this.startX, this.moveX),
          y2 = Math.max(this.startY, this.moveY);

        const svgPoint1 = this.toSvgPoint(x1, y1);
        const svgPoint2 = this.toSvgPoint(x2, y2);

        const centerX = (svgPoint2.x + svgPoint1.x) / 2,
          centerY = (svgPoint2.y + svgPoint1.y) / 2,
          radiusX = (svgPoint2.x - svgPoint1.x) / 2,
          radiusY = (svgPoint2.y - svgPoint1.y) / 2;

        ellipse.setAttribute("cx", centerX);
        ellipse.setAttribute("cy", centerY);
        ellipse.setAttribute("rx", radiusX);
        ellipse.setAttribute("ry", radiusY);
      }
    },
    createPath() {
      const path = this.getSvgElementById(this.currentId);
      if (path) {
        const point: SVGPoint = this.toSvgPoint(this.moveX, this.moveY);
        let updated = false;
        if (!this.lastPathPoint) {
          this.path.push(point);
          updated = true;
        } else {
          const nextPoint = this.svg.createSVGPoint();
          nextPoint.x = point.x - this.lastPathPoint.x;
          nextPoint.y = point.y - this.lastPathPoint.y;
          if (nextPoint.x !== 0 || nextPoint.y !== 0) {
            this.path.push(nextPoint);
            updated = true;
          }
        }
        if (updated) {
          path.setAttribute("d", this.toSVGPath(this.path));
          this.lastPathPoint = point;
        }
      }
    },
    toSVGPath(points: SVGPoint[]) {
      var SVGPath = "";
      for (var i = 0; i < points.length; i++) {
        var prefix = i == 0 ? "M" : "l";
        SVGPath += prefix + " " + points[i].x + " " + points[i].y + " ";
      }
      return SVGPath;
    },
    mouseUp(event: MouseEvent) {
      this.end(event.clientX, event.clientY, event.offsetX, event.offsetY);
    },
    touchEnd(event: TouchEvent) {
      if (this.telestrationMobileActive) {
        event.preventDefault();
        event.stopPropagation();
        if (event.changedTouches?.length > 0) {
          const touch = event.changedTouches[0];
          var rect = this.svg.getBoundingClientRect();
          this.end(
            touch.clientX,
            touch.clientY,
            touch.clientX - rect.left,
            touch.clientY - rect.top
          );
        } else {
          this.reset();
        }
      }
    },
    end(clientX: number, clientY: number, x: number, y: number) {
      if (!this.textEditing) {
        const elementAtPoint: SVGElement = this.getElementAt(clientX, clientY);
        if (this.eraseMode) {
          if (elementAtPoint) {
            this.eraseElement(elementAtPoint.id);
          }
        } else {
          if (this.started) {
            const id = this.currentId;
            if (!this.isValidShape(this.getSvgElementById(id))) {
              setTimeout(() => {
                this.eraseElement(id);
              }, this.updateTimeoutTime);
            }
            this.reset();
          }
          // Text currently disabled on touch devices
          if (!this.$store.state.session.isTouchDevice) {
            if (this.startUpdateExistingText(elementAtPoint)) {
              return;
            }
            if (this.shapeType.includes("text")) {
              const svgPoint = this.toSvgPoint(x, y);
              this.startTextEdit(
                this.generateUniqueId(),
                svgPoint.x,
                svgPoint.y,
                this.activeColour
              );
            }
          }
        }
      }
    },
    getZIndex() {
      const children = Array.from(this.svg.children);
      const ids = children.map((object: any) => {
        return object.getAttribute("z-index");
      });
      if (!ids || ids.length === 0) {
        return 200;
      }
      const max = Math.max(...ids);

      return Number(max + 1);
    },
    async sendShape(id: string, shape: SVGElement) {
      const element = this.elementToSvgString(shape);
      if (element) {
        await this.sessionApi.addElement(id, element);
      }
    },
    async updateShape(id: string, shape: SVGElement) {
      const element = this.elementToSvgString(shape);
      if (element) {
        await this.sessionApi.updateElement(id, element);
      }
      this.updateTimeout = false;
    },
    isValidShape(shape: SVGElement) {
      if (shape) {
        try {
          switch (shape.nodeName) {
            case "rect": {
              const width = shape.getAttribute("width");
              const height = shape.getAttribute("height");
              return (
                width &&
                height &&
                parseFloat(width) > 0 &&
                parseFloat(height) > 0
              );
            }

            case "ellipse": {
              const rx = shape.getAttribute("rx");
              const ry = shape.getAttribute("ry");
              return rx && ry && parseFloat(rx) > 0 && parseFloat(ry) > 0;
            }

            case "path":
              return shape.hasAttribute("d");

            case "text":
              return shape.textContent && shape.textContent !== "";

            default:
              return false;
          }
        } catch (err) {
          console.error("invalid shape", shape);
        }
      }
      return false;
    },
    elementToSvgString(element: SVGElement): string | undefined {
      if (element && element instanceof SVGElement) {
        try {
          var s = new XMLSerializer();
          var svgString = s.serializeToString(element);
          svgString = svgString.replace(' xmlns="' + SVG_NS + '"', "");
          return svgString;
        } catch (err) {
          console.error("error converting to SVG string", err);
          return undefined;
        }
      }
      return undefined;
    },
    stringToSvgElement(element: string) {
      const svgWrapper = new DOMParser()
        .parseFromString(
          `<svg xmlns="${SVG_NS}">${element}</svg>`,
          "image/svg+xml"
        )
        .getElementsByTagNameNS(SVG_NS, "svg")
        .item(0);
      if (svgWrapper && svgWrapper.firstChild) {
        return svgWrapper.firstChild;
      }
      return undefined;
    },
    reset() {
      this.started = false;
      this.startX = "";
      this.startY = "";
      this.moveX = "";
      this.moveY = "";
      this.endX = "";
      this.endY = "";
      this.rectWidth = 0;
      this.rectHeight = 0;
      this.textX = 0;
      this.textY = 0;
      this.textElementValue = "";
      this.previousTextValue = "";
      this.updatingExistingText = false;
      this.path = [];
      this.lastPathPoint = null;
      this.currentId = "";
      this.updateTimeout = false;
    },
    getElementAt(x: number, y: number): SVGElement | null {
      const element = document.elementFromPoint(x, y);
      if (element && element instanceof SVGElement) {
        const svgElement = element as SVGElement;
        if (
          svgElement.id &&
          !UNSELECTABLE_ELEMENT_IDS.includes(svgElement.id)
        ) {
          return svgElement;
        }
      }
      return null;
    },
    toSvgPoint(x: number, y: number): SVGPoint {
      const pt: SVGPoint = this.svg.createSVGPoint();
      pt.x = x;
      pt.y = y;

      const svgP: SVGPoint = pt.matrixTransform(this.getCTM().inverse());
      svgP.x = Math.round(svgP.x);
      svgP.y = Math.round(svgP.y);
      return svgP;
    },
    fromSvgPoint(x: number, y: number): SVGPoint {
      const pt: SVGPoint = this.svg.createSVGPoint();
      pt.x = x;
      pt.y = y;

      const svgP: SVGPoint = pt.matrixTransform(this.getCTM());
      return svgP;
    },
    getCTM(): DOMMatrix {
      let ctm: DOMMatrix = this.svg.getCTM();
      if (!ctm) {
        // Workaround for Firefox issue: https://bugzilla.mozilla.org/show_bug.cgi?id=1732331
        const origin = document.getElementById("origin");
        if (origin && origin instanceof SVGGraphicsElement) {
          ctm = (origin as SVGGraphicsElement).getCTM()!;
        }
      }
      return ctm;
    },
    startUpdateExistingText(element: SVGElement): boolean {
      if (element && element.nodeName === "text") {
        const id = element.id;
        const xStr = element.getAttribute("x");
        const yStr = element.getAttribute("y");
        const textContent = element.textContent;
        if (id && xStr && yStr && textContent) {
          this.updatingExistingText = true;
          this.previousTextValue = textContent;
          const colour = element.getAttribute("fill");
          this.startTextEdit(
            id,
            parseFloat(xStr),
            parseFloat(yStr) - TEXT_Y_OFFSET,
            colour
          );
          element.remove();
          return true;
        }
      }
      return false;
    },
    startTextEdit(id: string, x: number, y: number, colour: string) {
      this.textEditing = true;
      this.currentId = id;
      this.textX = x;
      this.textY = y;
      this.textElementValue = this.previousTextValue || "";
      this.textColour = colour;
      this.$nextTick(() => {
        this.$refs["textbox"].focus();
      });
    },
    endTextEdit() {
      if (this.textEditing) {
        this.textEditing = false;
        if (this.textElementValue && this.textElementValue !== "") {
          const text = document.createElementNS(SVG_NS, "text");
          text.setAttribute("id", this.currentId);
          text.setAttribute("z-index", this.getZIndex());
          text.setAttribute("class", "user-select-none");
          text.setAttribute("fill", this.textColour);
          text.setAttribute("stroke-width", "0");
          text.setAttribute("x", this.textX);
          text.setAttribute("y", this.textY + TEXT_Y_OFFSET); // Offset from text box display to actual text
          text.setAttribute("style", "font-size: 80px");
          text.textContent = this.textElementValue;

          this.svg.append(text);

          if (this.updatingExistingText) {
            this.updateShape(this.currentId, text);
          } else {
            this.sendShape(this.currentId, text);
          }
        } else if (this.updatingExistingText) {
          this.sessionApi.removeElement(this.currentId);
        }
        this.reset();
      }
    },
    cancelTextEdit() {
      this.textElementValue = this.previousTextValue;
      this.endTextEdit();
    },
    clearCanvas() {
      if (this.svg) {
        this.svg.textContent = "";
        if (!this.svg.getCTM()) {
          // Workaround for Firefox issue: https://bugzilla.mozilla.org/show_bug.cgi?id=1732331
          const origin = document.createElementNS(SVG_NS, "rect");
          origin.setAttribute("id", "origin");
          this.svg.append(origin);
        }
      }
    },
    setSvgElements(elements: string[]) {
      this.clearCanvas();
      this.sentShapeIds = [];
      for (const element of elements) {
        const svgElement = this.stringToSvgElement(element);
        if (svgElement) this.svg.append(svgElement);
      }
      this.checkGrid();
      this.checkHoldImage();
    },
    addElement(element: string, prepend?: boolean) {
      const newSvg = this.stringToSvgElement(element);
      if (newSvg) {
        if (prepend) {
          this.svg.prepend(newSvg);
        } else {
          this.svg.append(newSvg);
        }
      }
      this.checkGrid();
      this.checkHoldImage();
    },
    addOrUpdateElement(id: string, element: string, prepend?: boolean) {
      const existingSvg = this.getSvgElementById(id);
      const newSvg = this.stringToSvgElement(element);

      if (newSvg && !this.sentShapeIds.includes(id)) {
        if (existingSvg) {
          existingSvg.replaceWith(newSvg);
        } else {
          this.addElement(element, prepend);
        }
      }
    },
    removeElement(id: string) {
      const existingSvg = this.getSvgElementById(id);
      if (existingSvg) {
        existingSvg.remove();
      }
      this.checkGrid();
      this.checkHoldImage();
    },
    getSvgElementById(id: string) {
      return (this.svg as SVGSVGElement).getElementById(id);
    },
    setHoldImage(element: string) {
      this.addOrUpdateElement("holdImage", element, true);
    },
    removeHoldImage() {
      this.removeElement("holdImage");
    },
    checkHoldImage() {
      const held = this.getSvgElementById("holdImage") !== null;
      this.$store.dispatch("setTelestrationHeld", held);
    },
    isGridShowing() {
      return this.getSvgElementById("grid") !== null;
    },
    checkGrid() {
      this.$store.dispatch("setTelestrationGridActive", this.isGridShowing());
    },
    eraseElement(id: string) {
      this.removeElement(id);
      this.sessionApi.removeElement(id);
    },
    outOfCanvas() {
      if (this.telestrationMobileActive) {
        console.log("Out Of Canvas");
      }
    },
  },
})
export default class Telestration extends Vue {}
