import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "block bg-nucleus-white hover:bg-nucleus-listBgHover hover:border-nucleus-listBgHover p-3 text-nucleus-text rounded transition flex items-center border border-nucleus-white mb-1 link" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "font-bold flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["icon text-3xl leading-none p-2 text-nucleus-text mr-6 flex self-center transition bg-nucleus-iconBg rounded-full relative", 'icon-' + _ctx.icon])
    }, [
      (_ctx.microphoneActive)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "icon icon-mic-on text-sm leading-none p-0.5 text-nucleus-white flex self-center transition bg-nucleus-primaryBlue rounded-full absolute -top-0.5 -right-0.5",
            title: _ctx.$t('titles.micActive')
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1)
    ])
  ]))
}