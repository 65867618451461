
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    liveTelestrationId: String,
  },
  data() {
    return {
      menu: false,
      fullscreen: false,
      prefixes: ["", "moz", "webkit", "ms"],
    };
  },
  emits: ["triggerSidebar"],
  computed: {
    logo() {
      return "logo_black.svg";
    },
    pageName() {
      return this.$route.name;
    },
    telestrationMobileActive() {
      return this.$store.state.session.telestrationMobileActive;
    },
    isTouchDevice() {
      return this.$store.state.session.isTouchDevice;
    },
    isMobile() {
      return this.$store.state.session.isMobile;
    },
    isTablet() {
      return this.$store.state.session.isTablet;
    },
    isApple() {
      return this.$store.state.session.isApple;
    },
  },
  mounted() {
    this.fullscreenListen();

    this.$nextTick(() => {
      if (this.fullscreenAllowed()) {
        if (this.isTouchDevice) {
          if (!document.fullscreenElement) {
            document.getElementById("toggleFullscreen")?.click();
          } else {
            this.fullscreen = true;
          }
        }
      }
    });
  },
  methods: {
    fullscreenAllowed() {
      return !this.isApple || !this.isTouchDevice;
    },
    telestrationAllowed() {
      // if (this.isApple && this.isMobile) {
      //   return false;
      // }
      return this.liveTelestrationId && this.isTouchDevice;
    },
    triggerSidebar() {
      this.$emit("triggerSidebar");
    },
    fullscreenListen() {
      this.prefixes.forEach((prefix: string) => {
        document.addEventListener(
          prefix + "fullscreenchange",
          this.fullscreenFunc,
          false
        );
      });
    },
    fullscreenFunc() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      var doc = document as any;

      var fullscreenElement =
        doc.fullscreenElement /* Standard syntax */ ||
        doc.webkitFullscreenElement /* Chrome, Safari and Opera syntax */ ||
        doc.mozFullScreenElement /* Firefox syntax */ ||
        doc.msFullscreenElement; /* IE/Edge syntax */

      if (fullscreenElement) {
        this.fullscreen = true;
        if (this.fullscreenAllowed()) {
          if (this.isTouchDevice) {
            screen?.orientation?.lock("landscape");
          }
        }
      } else if (!doc.fullscreenchange) {
        this.fullscreen = false;
      }
    },
    toggleFullscreen() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      var doc = document as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      var docEl = doc.documentElement as any;

      var requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen ||
        docEl.webkitEnterFullscreen;
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement &&
        !doc.webkitDisplayingFullscreen
      ) {
        requestFullScreen.call(docEl);
      } else {
        cancelFullScreen.call(doc);
      }
    },
    toggleTelestration() {
      let boolean = !this.telestrationMobileActive;
      this.$store.dispatch("telestrationMobileActive", boolean);
    },
  },
})
export default class HeaderMini extends Vue {}
