
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import HeaderRemoteSession from "@/components/global/HeaderRemoteSession.vue";
import VideoStream from "@/components/utils/VideoStream.vue";
import VideoStreamTest from "@/components/utils/VideoStreamTest.vue";
import ButtonOutlined from "@/components/utils/ButtonOutlined.vue";
import NrasSessionApi, {
  Broadcast,
  TelestrationInterface,
  UserState,
  UserType,
} from "@/utils/NrasSessionApi";
import ListBroadcast from "@/components/utils/ListBroadcast.vue";
import ListUsers from "@/components/utils/ListUsers.vue";
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";
import Telestration from "@/components/utils/Telestration.vue";

enum DisconnectReason {
  SessionEnded = "SESSION_ENDED",
  InviteRevoked = "INVITE_REVOKED",
  UserRejoined = "USER_REJOINED",
  ClientUnresponsive = "CLIENT_UNRESPONSIVE",
  ClientConnectionClosed = "CLIENT_CONNECTION_CLOSED",
  ClientConnectionError = "CLIENT_CONNECTION_ERROR",
  NucleusConnectionError = "NUCLEUS_CONNECTION_ERROR",
  JoinError = "JOIN_ERROR",
}

@Options({
  components: {
    HeaderShadow,
    HeaderRemoteSession,
    VideoStream,
    VideoStreamTest,
    ButtonOutlined,
    ListBroadcast,
    ListUsers,
    LoadingScreen,
    ErrorBox,
    Telestration,
  },
  computed: {
    username() {
      return this.$store.state.session.session.username;
      // return this.$store.state.session;
    },
    sessionId() {
      return this.$store.state.session.session.sessionId;
    },
    sessionName() {
      return this.$store.state.session.session.sessionName;
    },
    telestrationMobileActive() {
      return this.$store.state.session.telestrationMobileActive;
    },
    isTouchDevice() {
      return this.$store.state.session.isTouchDevice;
    },
    reconnectCount(): number {
      return this.$store.state.session.reconnect;
    },
    liveBroadcast() {
      return this.$store.state.session.liveBroadcast;
    },
    orderedBroadcasts() {
      // Alphanumeric sort
      return this.broadcasts.slice().sort((a: Broadcast, b: Broadcast) => {
        let aName = a.name.toLowerCase();
        let bName = b.name.toLowerCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      });
    },
    orderedUsers() {
      // Alphanumeric sort with the current user at the start of the list
      return this.users.slice().sort((a: UserState, b: UserState) => {
        if (a.self && !b.self) return -1;
        if (b.self && !a.self) return 1;
        let aName = a.username.toLowerCase();
        let bName = b.username.toLowerCase();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      });
    },
  },
  data() {
    return {
      sessionApi: null,
      connecting: false,
      reconnectOnClose: true,
      reconnectDelay: 0,
      inviteId: undefined,
      broadcasts: new Array<Broadcast>(),
      telestrations: new Array<TelestrationInterface>(),
      users: new Array<UserState>(),
      // liveBroadcast: {},
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      headerActive: true,
      sidebarActive: false,
      headerTimeout: null,
      appHeight: "",
      networkType: "",
      conferenceStateChecker: undefined,
      initialBroadcastSelectTimeout: null,
    };
  },
  async mounted() {
    const { sessionId, accessToken } = this.$store.state.session.session;
    this.inviteId = this.$route.query.inviteId;
    if (!sessionId || !accessToken) {
      if (this.inviteId) {
        await this.$router.replace({
          path: `/remote-client/access/${this.inviteId}`,
        });
      } else {
        this.errorHeading = this.$t("pages.remoteSession.cantRejoin");
        this.errorContent = this.$t("pages.remoteSession.emailLink");
      }
    } else {
      this.sessionApi = this.createApi(sessionId, accessToken);
      document.title =
        this.sessionName + " - " + this.$t("global.defaults.browserTitle");
      if (this.isTouchDevice) {
        this.headerTimeoutCall();
        this.mobileLayout();
      }
    }

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
      this.appHeight = window.innerHeight;
    };
    window.addEventListener("resize", appHeight);
    appHeight();

    this.networkType = navigator?.connection?.type;

    console.log("navigator", navigator);
    console.log("network type", this.networkType);

    navigator?.connection?.addEventListener(
      "change",
      this.updateConnectionStatus
    );
  },
  unmounted() {
    // console.log("Session API", this.sessionApi);
    this.sessionApi?.close();
    screen?.orientation?.unlock();

    if (this.conferenceStateChecker) {
      clearInterval(this.conferenceStateChecker);
    }
  },
  // updated() {
  //   console.log("UPDATED: Session API", this.sessionApi);
  // },
  methods: {
    createApi(sessionId: string, accessToken: string) {
      console.log(
        "creating sessionAPI - should only see this once!",
        sessionId,
        accessToken
      );
      // const session = this.sessionState;

      // console.log("Session info", session);

      // const proxyFix = Object.assign({}, session);

      // console.log("Session proxy", proxyFix);

      let sessionApi = new NrasSessionApi(sessionId, accessToken);
      this.connecting = true;

      sessionApi.onClientResponse(() => {
        this.connecting = false;
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sessionApi.onClose((reason: unknown) => {
        console.log("Server connection closed", reason);
        this.connecting = false;
        if (this.reconnectOnClose) {
          if (this.reconnectDelay <= 0) {
            this.$store.dispatch("sessionReconnect");
          } else {
            const countdown = setInterval(() => {
              if (this.reconnectDelay <= 0) {
                this.$store.dispatch("sessionReconnect");
                clearInterval(countdown);
              }
              this.reconnectDelay--;
            }, 1000);
          }
        }
      });

      sessionApi.onError((reason: unknown) => {
        console.error("I couldn't connect to the server!?", reason);
        this.connecting = false;

        this.errorHeading = this.$t("pages.remoteSession.error");
        this.errorContent = this.$t("pages.remoteSession.emailLink");
      });

      sessionApi.onSessionEnded((reason: string) => {
        switch (reason) {
          case DisconnectReason.SessionEnded:
            this.reconnectOnClose = false;
            this.errorHeading = this.$t("pages.sessionEnded.title");
            this.errorContent = "";
            break;

          case DisconnectReason.UserRejoined:
            this.reconnectOnClose = false;
            this.errorHeading = this.$t("pages.sessionEnded.connectOne");
            this.errorContent = "";
            break;

          case DisconnectReason.InviteRevoked:
            this.reconnectOnClose = false;
            this.errorHeading = this.$t("pages.sessionEnded.removed");
            this.errorContent = "";
            break;

          case DisconnectReason.ClientUnresponsive:
          case DisconnectReason.ClientConnectionClosed:
          case DisconnectReason.ClientConnectionError:
            this.reconnectOnClose = true;
            break;

          case DisconnectReason.NucleusConnectionError:
            this.reconnectOnClose = true;
            this.reconnectDelay = 5;
            break;

          case DisconnectReason.JoinError:
            if (this.reconnectCount > 0) {
              // Auto reconnect if we were connected before
              this.reconnectOnClose = true;
              this.reconnectDelay = 5;
            } else {
              // Don't auto reconnect if this is the first time trying to connect
              this.reconnectOnClose = false;
              this.errorHeading = this.$t("pages.sessionEnded.error");
              this.errorContent = this.$t("pages.sessionEnded.tryAgain");
            }
            break;

          default:
            this.reconnectOnClose = false;
            this.errorHeading = this.$t("pages.sessionEnded.disconnected");
            this.errorContent = this.$t("pages.sessionEnded.rejoinHint");
            break;
        }

        if (!this.reconnectOnClose) {
          this.$router.push({
            name: "RemoteClientEnded",
            query: { reason: reason },
          });
        }
      });

      sessionApi.onInitialElements((elements: string[]) => {
        const canvas = this.getTelestrationCanvas();
        if (canvas) {
          canvas.setSvgElements(elements);
        }
      });

      sessionApi.onAddElement((id: string, svg: string) => {
        const canvas = this.getTelestrationCanvas();
        if (canvas) {
          if (id === "holdImage") {
            canvas.setHoldImage(svg);
          } else {
            canvas.addOrUpdateElement(id, svg);
          }
        }
      });

      sessionApi.onUpdateElement((id: string, svg: string) => {
        const canvas = this.getTelestrationCanvas();
        if (canvas) {
          if (id === "holdImage") {
            canvas.setHoldImage(svg);
          } else {
            canvas.addOrUpdateElement(id, svg);
          }
        }
      });

      sessionApi.onRemoveElement((id: string) => {
        const canvas = this.getTelestrationCanvas();
        if (canvas) {
          if (id === "holdImage") {
            canvas.removeHoldImage();
          } else {
            canvas.removeElement(id);
          }
        }
      });

      sessionApi.onClearElements(() => {
        console.warn("Todo: remove all annotations");
      });

      sessionApi.onUserJoined(
        (
          userId: string,
          self: boolean,
          username: string,
          userType: UserType,
          hasAudio: boolean,
          speakerActive: boolean,
          microphoneActive: boolean
        ) => {
          // Only add new entry if a user with the same ID doesn't already exist on the list
          if (
            this.users.filter((u: UserState) => u.userId === userId).length ===
            0
          ) {
            this.users.push({
              userId,
              self,
              username,
              userType,
              hasAudio,
              speakerActive,
              microphoneActive,
            });
          }
        }
      );

      sessionApi.onUserLeft((userId: string, _self: boolean) => {
        this.users = this.users.filter((u: UserState) => u.userId !== userId);
      });

      sessionApi.onUserStateChanged(
        (
          userId: string,
          _self: boolean,
          hasAudio: boolean,
          speakerActive: boolean,
          microphoneActive: boolean
        ) => {
          this.users
            .filter((u: UserState) => u.userId === userId)
            .forEach((u: UserState) => {
              u.hasAudio = hasAudio;
              u.speakerActive = speakerActive;
              u.microphoneActive = microphoneActive;
            });
        }
      );

      sessionApi.onRoomState((users: UserState[]) => {
        this.users = users;
      });

      sessionApi.onBroadcastStarted(
        (id: string, name: string, telestrationId: string) => {
          console.log("Broadcast Started", id, name);
          // Only add new entry if a broadcast with the same ID doesn't already exist on the list
          if (
            this.broadcasts.filter((b: Broadcast) => b.id === id).length === 0
          ) {
            this.broadcasts.push({ id, name, telestrationId });
            if (!this.liveBroadcast.id) {
              /* 
                If no broadcast selected, select the first one (according to the order in the UI).
                When the session starts, we get a separate BroadcastStarted events for each broadcast.
                These will not necessarily arrive in the same order as we are displaying the broadcasts in the UI.
                So give a little time for all the events to arrive before we choose the one to select.
              */
              if (this.initialBroadcastSelectTimeout) {
                clearTimeout(this.initialBroadcastSelectTimeout);
              }
              this.initialBroadcastSelectTimeout = setTimeout(() => {
                this.switchBroadcast(this.orderedBroadcasts[0]);
                this.initialBroadcastSelectTimeout = null;
              }, 500);
            } else {
              // Now that liveBroadcast persists
              this.switchBroadcast(
                this.broadcasts.filter(
                  (broadcast: any) => broadcast.id === this.liveBroadcast.id
                )[0]
              );
            }
          }
        }
      );

      sessionApi.onBroadcastEnded((id: string) => {
        this.broadcasts = this.broadcasts.filter((b: Broadcast) => b.id !== id);
        if (id === this.liveBroadcast.id)
          this.$store.dispatch("setLiveBroadcast", {});
      });

      sessionApi.onThumbnail((id: string, data: string) => {
        let broadcast = this.broadcasts.find((b: Broadcast) => b.id === id);
        if (broadcast) broadcast.thumbnailData = data;
      });

      sessionApi.onTelestrationStarted((id: string, telestrationId: string) => {
        console.log("Telestration started", id, telestrationId);
        let broadcast = this.broadcasts.find((b: Broadcast) => b.id === id);
        if (broadcast) broadcast.telestrationId = telestrationId;
        this.telestrations.push({ id, telestrationId });
      });

      sessionApi.onTelestrationEnded((id: string) => {
        console.log("Telestration ended", id);
        let broadcast = this.broadcasts.find((b: Broadcast) => b.id === id);
        if (broadcast) delete broadcast.telestrationId;
        this.telestrations = this.telestrations.filter(
          (b: TelestrationInterface) => b.id !== id
        );
      });

      sessionApi.onTelestrationChanged((id: string, telestrationId: string) => {
        let broadcast = this.broadcasts.find((b: Broadcast) => b.id === id);
        if (broadcast) broadcast.telestrationId = telestrationId;
        console.log("Telestration changed", id, telestrationId);
      });

      this.conferenceStateChecker = setInterval(() => {
        this.checkUserConferenceState();
        this.sessionApi.getRoomState();
      }, 5000);

      return sessionApi;
    },
    getTelestrationCanvas() {
      return this.$refs.videoStream.getTelestrationCanvas();
    },
    switchBroadcast(broadcast: Broadcast) {
      if (this.liveBroadcast !== broadcast) {
        this.sessionApi.switchBroadcast(broadcast.id);
        this.$store.dispatch("setLiveBroadcast", broadcast);
        // this.liveBroadcast = broadcast;
        if (this.$refs.telestration) {
          const canvas = this.getTelestrationCanvas();
          if (canvas) {
            canvas.clearCanvas();
            canvas.checkGrid();
          }
        }
      }
    },
    changeMic() {
      this.$refs.videoStream.openDevicesModal();
    },
    headerToggle(event: TouchEvent) {
      if (
        this.isTouchDevice &&
        !this.telestrationMobileActive &&
        event.target instanceof Element
      ) {
        const element = event.target as Element;
        const video = document.getElementById("videoConstraints");
        const canvas = document.getElementById("canvas");
        if (
          this.isDescendant(video, element) ||
          this.isDescendant(canvas, element)
        ) {
          if (!this.sidebarActive) {
            this.headerActive = !this.headerActive;

            if (this.headerActive) {
              this.headerTimeoutCall();
            }
          } else {
            this.headerActive = false;
            this.sidebarActive = false;
          }
        } else if (this.headerActive && !this.sidebarActiv) {
          this.restartTimeout();
        }
      }
    },
    isDescendant(parent: Node, child: Node) {
      if (!parent) return false;
      if (!child) return false;
      if (parent === child) return true;
      let node = child.parentNode;
      while (node) {
        if (node === parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    triggerSidebar() {
      this.clearHeaderTimeout();
      this.headerActive = true;
      this.sidebarActive = !this.sidebarActive;

      if (!this.sidebarActive) {
        this.headerTimeoutCall();
      }
    },
    headerTimeoutCall() {
      this.clearHeaderTimeout();
      this.headerTimeout = setTimeout(() => {
        if (!this.sidebarActive) {
          this.headerActive = false;
          this.sidebarActive = false;
        }
        this.headerTimeout = null;
      }, 5000);
    },
    restartTimeout() {
      if (this.isTouchDevice) {
        if (this.headerTimeout) {
          this.headerTimeoutCall();
        }
      }
    },
    clearHeaderTimeout() {
      if (this.headerTimeout) {
        clearTimeout(this.headerTimeout);
        this.headerTimeout = null;
      }
    },
    mobileLayout() {
      document.getElementById("page")?.classList.add("mobile-layout");
    },
    activateIphone() {
      document.getElementById("page")?.classList.toggle("iphone");
    },
    scroll1() {
      window.scrollTo({ top: 1 });
    },
    scroll100() {
      window.scrollTo({ top: 150 });
    },

    preventDefault(event: TouchEvent) {
      if (this.telestrationMobileActive) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    videoReconnect() {
      this.reconnectOnClose = true;
      this.sessionApi?.close();
    },
    closeSession() {
      this.reconnectOnClose = false;
      this.sessionApi?.close();
    },
    updateConnectionStatus() {
      console.log(
        `Connection type changed from ${this.networkType} to ${navigator?.connection?.type}`
      );

      if (
        this.networkType === "wifi" &&
        navigator?.connection?.type === "cellular"
      ) {
        console.log(
          "Moving from wifi to cellular, should never connect so need to inform the user to reload and enter access code again"
        );
      }

      console.log("Updating network type");
      if (navigator?.connection?.type !== "none") {
        this.networkType = navigator?.connection?.type;
      }
    },
    checkUserConferenceState() {
      const speakerActive = !this.$store.state.session.audioMuted;
      const micActive =
        !this.$store.state.session.micMuted &&
        !this.$store.state.session.audioMuted;
      const filteredUsers: UserState[] = this.users.filter(
        (user: UserState) => user.self
      );
      if (filteredUsers.length > 0) {
        const user = filteredUsers[0];
        if (
          user &&
          (user.microphoneActive !== micActive ||
            user.speakerActive !== speakerActive)
        ) {
          console.log("############################################");
          console.log(
            `Mismatch between local and remote audio state. Current state: speakerActive=${user.speakerActive} microphoneActive=${user.microphoneActive}, Actual state: speakerActive=${speakerActive} microphoneActive=${micActive}`
          );
          console.log("############################################");
          this.sessionApi.updateConferenceState(speakerActive, micActive);
        }
      }
    },
  },
})
export default class RemoteClientSession extends Vue {}
