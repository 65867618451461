
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    icon: String,
    image: String,
    status: String,
    thumbnailData: String,
    liveBroadcastId: String,
    broadcastId: String,
    liveTelestrationId: String,
    telestrationId: String,
  },
  methods: {
    getStatusColor() {
      const text = this.status.toLowerCase();
      switch (text) {
        case "live":
        case "enabled":
          return "text-nucleus-primaryBlue font-bold";
        case "pending":
          return "text-nucleus-text";
        default:
          return "text-nucleus-text";
      }
    },
    getStatusText() {
      const text = this.status.toLowerCase();
      switch (text) {
        case "live":
          return this.$t("pages.remoteSession.live");
        default:
          return this.status;
      }
    },
    getThumbnailSrc() {
      return `data:image/jpeg;base64,${this.thumbnailData}`;
    },
  },
})
export default class ListItem extends Vue {}
