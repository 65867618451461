<template>
  <div
    class="
      rounded-full
      h-11
      w-11
      flex
      items-center
      justify-center
      text-nucleus-grey
      hover:text-nucleus-text
      transition-all
      cursor-pointer
    "
  >
    <slot></slot>
  </div>
</template>
