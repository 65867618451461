import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "muted"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("audio", {
    id: 'audio_' + _ctx.userId,
    ref: "audio",
    autoplay: "",
    muted: _ctx.audioMuted
  }, null, 8, _hoisted_1))
}