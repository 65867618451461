import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-393fa4a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mr-2 w-28 flex-grow-0 flex-shrink-0" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex items-center justify-center mr-2 text-3xl text-nucleus-grey" }
const _hoisted_4 = {
  key: 0,
  class: "icon-broadcast"
}
const _hoisted_5 = {
  key: 1,
  class: "icon-telestration"
}
const _hoisted_6 = { class: "mr-2 overflow-ellipsis overflow-hidden" }
const _hoisted_7 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-nucleus-white hover:bg-nucleus-listBgHover hover:border-nucleus-listBgHover text-nucleus-text transition rounded-tr rounded-br flex flex-row items-center overflow-ellipsis overflow-hidden border-nucleus-white border-2 mb-1 link", {
      'border-nucleus-listBorderActive bg-nucleus-listBgActive hover:border-nucleus-listBorderActive hover:bg-nucleus-listBgActive':
        _ctx.broadcastId === _ctx.liveBroadcastId,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "display-block",
        src: _ctx.getThumbnailSrc()
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.telestrationId)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.telestrationId)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["text-sm", _ctx.getStatusColor()])
      }, _toDisplayString(_ctx.getStatusText()), 3)
    ])
  ], 2))
}