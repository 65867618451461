<template>
  <video
    autoplay
    muted
    loop
    playsinline
    controls="controls"
    preload="none"
    src="https://dl8.webmfiles.org/big-buck-bunny_trailer.webm"
    class="object-fill w-full border-2 border-white"
  ></video>
</template>
