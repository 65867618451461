import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17d6a808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between items-center p-4 bg-nucleus-white dark:bg-nucleus-darkBlue" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "w-100px mr-25px" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "font-bold text-nucleus-text truncate session-name" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "block align-middle text-4xl text-nucleus-grey dark:text-white" }
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = { class: "block align-middle text-4xl text-nucleus-grey dark:text-white" }
const _hoisted_12 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isTouchDevice)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.triggerSidebar())),
            class: "icon-hamburger text-3xl mr-6",
            title: _ctx.$t('titles.sidebar')
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: require(`@/assets/logo_black.svg`),
          alt: "Sony Logo Black",
          class: "max-w-full h-auto"
        }, null, 8, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.fullscreenAllowed())
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleFullscreen())),
            class: "block ml-25px",
            id: "toggleFullscreen",
            "aria-label": 'Fullscreen'
          }, [
            _createElementVNode("span", _hoisted_8, [
              (_ctx.fullscreen)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "icon-exit-fullscreen",
                    title: _ctx.$t('titles.closeFullscreen')
                  }, null, 8, _hoisted_9))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: "icon-fullscreen",
                    title: _ctx.$t('titles.enableFullscreen')
                  }, null, 8, _hoisted_10))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.telestrationAllowed())
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleTelestration())),
            class: "block ml-4",
            id: "toggleTelestration",
            "aria-label": 'Telestration'
          }, [
            _createElementVNode("span", _hoisted_11, [
              _createElementVNode("span", {
                class: "icon-telestration",
                title: _ctx.$t('titles.telestration')
              }, null, 8, _hoisted_12)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}