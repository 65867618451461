
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    icon: String,
    image: String,
    speakerActive: Boolean,
    microphoneActive: Boolean,
  },
  methods: {},
})
export default class ListItem extends Vue {}
