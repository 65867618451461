
import { Options, Vue } from "vue-class-component";
import NrasSessionApi from "../../utils/NrasSessionApi";
import ButtonCircle from "@/components/utils/ButtonCircle.vue";
import DropdownTelestration from "@/components/utils/DropdownTelestration.vue";
import ModalConfirmation from "@/components/global/ModalConfirmation.vue";

@Options({
  components: {
    ButtonCircle,
    DropdownTelestration,
    ModalConfirmation,
  },
  props: {
    sessionApi: NrasSessionApi,
  },
  data() {
    return {
      menuActive: "",
      colourFade: "500",
      colours: ["black", "red", "blue", "yellow", "hotpink", "white"],
      thicknessList: ["1", "2", "3", "4", "5", "6"],
      rectangleCSS: "border-2 border-nucleus-grey h-5 w-5",
      rectangleFillCSS: "border-2 border-nucleus-grey bg-nucleus-grey h-5 w-5",
      ellipseCSS: "border-2 border-nucleus-grey rounded-full h-5 w-5",
      ellipseFillCSS:
        "border-2 border-nucleus-grey rounded-full bg-nucleus-grey h-5 w-5",
      pathCSS: "icon-telestration-freehand text-3xl",
      textCSS: "text-md abc font-semibold",
      confirm: undefined,
      videoHeld: false,
      holdButtonDisabled: false,
    };
  },
  created() {
    this.videoHeld = this.$store.state.session.telestration.held;
    this.unwatch = this.$store.watch(
      (state: any, _getters: any) => state.session.telestration.held,
      (held: boolean, _oldValue: boolean) => {
        this.videoHeld = held;
        this.holdButtonDisabled = false;
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  computed: {
    shapeType() {
      return this.$store.state.session.telestration.shapeType;
    },
    strokeWidth() {
      return this.$store.state.session.telestration.strokeWidth;
    },
    activeColour() {
      return this.$store.state.session.telestration.activeColour;
    },
    eraseMode() {
      return this.$store.state.session.telestration.eraseMode;
    },
    gridActive() {
      return this.$store.state.session.telestration.gridActive;
    },
    isTouchDevice() {
      return this.$store.state.session.isTouchDevice;
    },
    telestrationMobileActive() {
      return this.$store.state.session.telestrationMobileActive;
    },
  },
  methods: {
    getShapeTypeClasses() {
      switch (this.$store.state.session.telestration.shapeType) {
        case "rectangle":
          return this.rectangleCSS;
        case "rectangleFill":
          return this.rectangleFillCSS;
        case "ellipse":
          return this.ellipseCSS;
        case "ellipseFill":
          return this.ellipseFillCSS;
        case "path":
          return this.pathCSS;
        case "text":
          return this.textCSS;
        default:
          return "";
      }
    },
    toggleShapeType(type: string) {
      this.menuActive = "";
      this.$store.dispatch("setTelestrationShapeType", type);
    },
    async toggleGrid() {
      if (this.$store.state.session.telestration.gridActive) {
        await this.sessionApi.hideGrid();
      } else {
        await this.sessionApi.showGrid();
      }
    },
    toggleErase() {
      const newEraseMode = !this.$store.state.session.telestration.eraseMode;
      this.$store.dispatch("setTelestrationEraseMode", newEraseMode);
    },
    toggleHold() {
      this.holdButtonDisabled = true;
      if (!this.videoHeld) {
        this.sessionApi.holdStream();
      } else {
        this.sessionApi.resumeStream();
      }
      this.videoHeld = !this.videoHeld;
    },
    toggleMenu(menu: string) {
      this.$store.dispatch("setTelestrationEraseMode", false);
      if (menu) {
        if (this.menuActive === menu) {
          this.menuActive = "";
        } else {
          this.menuActive = menu;
        }
      } else {
        this.menuActive = "";
      }
    },
    closeTelestration() {
      this.$store.dispatch("telestrationMobileActive", false);
    },
    changeColour(colour: string) {
      this.$store.dispatch("setTelestrationColour", colour);
      this.menuActive = "";
    },
    changeThickness(thickness: string) {
      this.$store.dispatch("setTelestrationStrokeWidth", thickness);
      this.menuActive = "";
    },
    confirmClearAll() {
      this.confirm = {
        text: this.$t("pages.tenancy.deleteAnnotations"),
        yes: () => {
          this.clearAll();
          this.confirm = undefined;
          this.menuActive = "";
        },
        no: () => {
          this.confirm = undefined;
          this.menuActive = "";
        },
      };
    },
    clearAll() {
      this.sessionApi.clearElements();
      this.menuActive = "";
    },
  },
})
export default class Telestration extends Vue {}
