import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, vModelText as _vModelText, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c714702"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1",
      onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseDown && _ctx.mouseDown(...args))),
      onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.touchStart && _ctx.touchStart(...args))),
      onMousemove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mouseMove && _ctx.mouseMove(...args))),
      onTouchmove: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.touchMove && _ctx.touchMove(...args))),
      onMouseup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.mouseUp && _ctx.mouseUp(...args))),
      onTouchend: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.touchEnd && _ctx.touchEnd(...args))),
      onOnmouseleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.outOfCanvas && _ctx.outOfCanvas(...args))),
      onTouchcancel: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.outOfCanvas && _ctx.outOfCanvas(...args))),
      class: _normalizeClass(["w-full h-full inline-block", { 'no-touch': _ctx.telestrationMobileActive }]),
      viewBox: "0 0 1920 1080",
      id: "svg"
    }, null, 34)),
    (_ctx.textEditing)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "absolute",
          style: _normalizeStyle([
      'left: ' + _ctx.textBoxPos.x + 'px',
      'top: ' + _ctx.textBoxPos.y + 'px',
      'width: ' + (_ctx.canvasWidth - _ctx.textBoxPos.x) + 'px',
    ])
        }, [
          _withDirectives(_createElementVNode("input", {
            ref: "textbox",
            class: "w-full bg-transparent nucleusfont",
            style: _normalizeStyle([
        'font-size: ' + (80 * _ctx.canvasHeight) / 1080 + 'px',
        'color: ' + _ctx.textColour,
      ]),
            type: "text",
            placeholder: _ctx.$t('inputs.textEdit.placeholder'),
            onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.endTextEdit && _ctx.endTextEdit(...args))),
            onKeydown: [
              _cache[9] || (_cache[9] = _withKeys(
//@ts-ignore
(...args) => (_ctx.cancelTextEdit && _ctx.cancelTextEdit(...args)), ["esc"])),
              _cache[10] || (_cache[10] = _withKeys(
//@ts-ignore
(...args) => (_ctx.endTextEdit && _ctx.endTextEdit(...args)), ["enter"]))
            ],
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.textElementValue) = $event))
          }, null, 44, _hoisted_1), [
            [_vModelText, _ctx.textElementValue]
          ])
        ], 4))
      : _createCommentVNode("", true)
  ], 64))
}