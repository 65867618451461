
import { Options, Vue } from "vue-class-component";

import Button from "@/components/utils/Button.vue"; // @ is an alias to /src
import ButtonSecondary from "@/components/utils/ButtonSecondary.vue"; // @ is an alias to /src
import Select from "@/components/utils/Select.vue"; // @ is an alias to /src

@Options({
  components: {
    Button,
    ButtonSecondary,
    Select,
  },
  emits: ["closeModal", "selectDevices"],
  props: {
    text: String,
    initialInputDeviceId: String,
    initialOutputDeviceId: String,
    mediaDevices: Array,
  },
  data() {
    return {
      inputDeviceId: this.initialInputDeviceId,
      outputDeviceId: this.initialOutputDeviceId,
    };
  },
  computed: {
    inputDeviceOptions() {
      return this.mediaDevices
        .filter(
          (deviceInfo: MediaDeviceInfo) => deviceInfo.kind === "audioinput"
        )
        .map((deviceInfo: MediaDeviceInfo) => {
          return { id: deviceInfo.deviceId, name: deviceInfo.label };
        });
    },
    outputDeviceOptions() {
      return this.mediaDevices
        .filter(
          (deviceInfo: MediaDeviceInfo) => deviceInfo.kind === "audiooutput"
        )
        .map((deviceInfo: MediaDeviceInfo) => {
          return { id: deviceInfo.deviceId, name: deviceInfo.label };
        });
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    applyModal() {
      console.log("OUTPUT DEVICES", this.outputDeviceOptions);
      console.log("INPUT DEVICES", this.inputDeviceOptions);

      this.$emit("selectDevices", this.inputDeviceId, this.outputDeviceId);
      this.$emit("closeModal");
    },
  },
})
export default class ModalDeviceSelection extends Vue {}
