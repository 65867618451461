type NrasMessageId =
  | "Client"
  | "ClientResponse"
  | "IceCandidate"
  | "ConsumeAudio"
  | "StopConsumeAudio"
  | "SwitchBroadcast"
  | "BroadcastStarted"
  | "BroadcastEnded"
  | "GetRoomState"
  | "RoomState"
  | "TelestrationStarted"
  | "TelestrationEnded"
  | "TelestrationChanged"
  | "GetElements"
  | "AddElement"
  | "UpdateElement"
  | "RemoveElement"
  | "ClearElements"
  | "ShowGrid"
  | "HideGrid"
  | "HoldStream"
  | "ResumeStream"
  | "ConferenceUserJoined"
  | "ConferenceUserLeft"
  | "ConferenceUserStateChanged"
  | "ConferenceUserState"
  | "SessionEnded"
  | "Ping"
  | "Pong";

export type MediaType = "VIDEO" | "AUDIO";
export type UserType = "REMOTE" | "LOCAL";

const WS_URL = process.env.VUE_APP_SESSION_API_URL!.replace(
  "https://",
  "wss://"
);

const MAX_RETRIES = 150;

export interface Broadcast {
  id: string;
  name: string;
  telestrationId: string;
}

export interface TelestrationInterface {
  id: string;
  telestrationId: string;
}

export interface UserState {
  userId: string;
  self: boolean;
  username: string;
  userType: string;
  hasAudio: boolean;
  speakerActive: boolean;
  microphoneActive: boolean;
}

export interface SvgElement {
  elementId: string;
  elementZIndex: number;
  elementSvg: string;
}

export default class NrasSessionApi {
  private readonly sessionId: string;
  private readonly authToken: string;
  private ws: WebSocket;
  private onOpenBacklog: string[] = [];
  private onClientResponseBacklog: string[] = [];

  private closeHandlers: { (reason: any): void }[] = [];
  private errorHandlers: { (reason: any): void }[] = [];
  private clientResponseHandlers: {
    (sdpAnswers: { video: string; audio: string }): void;
  }[] = [];
  private iceCandidateHandlers: {
    (
      mediaType: MediaType,
      candidate: string,
      sdpMid: string,
      sdpMLineIndex: string,
      conferenceUserId?: string
    ): void;
  }[] = [];
  private consumeAudioRepsponseHandlers: {
    (conferenceUserId: string, sdpAnswer: string): void;
  }[] = [];
  private sessionEndedHandlers: { (reason: string): void }[] = [];
  private initialElementHandlers: { (svgs: string[]): void }[] = [];
  private addElementHandlers: { (id: string, svg: string): void }[] = [];
  private updateElementHandlers: { (id: string, svg: string): void }[] = [];
  private removeElementHandlers: { (id: string): void }[] = [];
  private clearElementsHandlers: { (): void }[] = [];
  private conferenceUserJoinedHandlers: {
    (
      userId: string,
      self: boolean,
      username: string,
      userType: UserType,
      hasAudio: boolean,
      speaker: boolean,
      microphone: boolean
    ): void;
  }[] = [];
  private conferenceUserLeftHandlers: {
    (userId: string, self: boolean): void;
  }[] = [];
  private conferenceUserStateChangedHandlers: {
    (
      userId: string,
      self: boolean,
      hasAudio: boolean,
      speaker: boolean,
      microphone: boolean
    ): void;
  }[] = [];
  private broadcastStartedHandlers: {
    (id: string, name: string, telestrationId: string): void;
  }[] = [];
  private broadcastEndedHandlers: { (id: string): void }[] = [];
  private telestrationStartedHandlers: {
    (id: string, telestrationId: string): void;
  }[] = [];
  private telestrationEndedHandlers: { (id: string): void }[] = [];
  private telestrationChangedHandlers: {
    (id: string, telestrationId: string): void;
  }[] = [];
  private thumbnailHandlers: { (id: string, data: string): void }[] = [];
  private roomStateHandlers: { (userStates: UserState[]): void }[] = [];
  private hasConnected = false;
  private gotClientResponse = false;
  private retryCount = 0;

  constructor(sessionId: string, authToken: string) {
    this.sessionId = sessionId;
    this.authToken = authToken;

    console.log("New NrasSessionApi");

    this.ws = this.connect();
  }

  connect() {
    console.log("NrasSessionAPI Connect");
    this.ws = new WebSocket(
      `${WS_URL}?sessionId=${this.sessionId}&auth=${this.authToken}`
    );

    this.ws.onerror = (reason: any) => {
      if (!this.hasConnected) {
        if (this.retryCount < MAX_RETRIES) {
          const delay = 2000; //2 ** this.retryCount * 500;
          this.retryCount = this.retryCount + 1;
          console.error(
            `WebSocket Initial Connection Error - Retry ${this.retryCount} of ${MAX_RETRIES} `,
            reason,
            delay
          );
          setTimeout(() => this.connect(), delay);
        } else {
          console.error(
            "WebSocket Initial Connection Error - Retry Count Exceeded",
            reason
          );
          this.errorHandlers.forEach((h) => h(reason));
        }
      } else {
        this.errorHandlers.forEach((h) => h(reason));
      }
    };

    this.ws.onclose = (reason: any) => {
      if (this.hasConnected) {
        this.closeHandlers.forEach((h) => h(reason));
      }
    };

    this.ws.onopen = () => {
      this.hasConnected = true;
      this.retryCount = 0;

      this.ws.onmessage = (event: any) => {
        const msg = event.data;
        if (typeof msg == "string") {
          this.handle(JSON.parse(msg));
        }
      };

      const backlog = this.onOpenBacklog.reverse();
      this.onOpenBacklog = [];
      while (backlog.length > 0) {
        this.ws.send(backlog.pop()!);
      }
    };

    return this.ws;
  }

  close() {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }
  }

  removeAllListeners() {
    this.closeHandlers = [];
    this.errorHandlers = [];
    this.clientResponseHandlers = [];
    this.iceCandidateHandlers = [];
    this.consumeAudioRepsponseHandlers = [];
    this.sessionEndedHandlers = [];
    this.initialElementHandlers = [];
    this.addElementHandlers = [];
    this.updateElementHandlers = [];
    this.removeElementHandlers = [];
    this.clearElementsHandlers = [];
    this.conferenceUserJoinedHandlers = [];
    this.conferenceUserLeftHandlers = [];
    this.conferenceUserStateChangedHandlers = [];
    this.broadcastStartedHandlers = [];
    this.broadcastEndedHandlers = [];
    this.telestrationStartedHandlers = [];
    this.telestrationEndedHandlers = [];
    this.telestrationChangedHandlers = [];
    this.thumbnailHandlers = [];
  }

  onClose(handler: (reason: any) => void) {
    this.closeHandlers.push(handler);
  }

  onError(handler: (reason: any) => void) {
    this.errorHandlers.push(handler);
  }

  onClientResponse(
    handler: (sdpAnswers: { video: string; audio: string }) => void
  ) {
    this.clientResponseHandlers.push(handler);
  }

  onIceCandidate(
    handler: (
      mediaType: MediaType,
      candidate: string,
      sdpMid: string,
      sdpMLineIndex: string,
      conferenceUserId?: string
    ) => void
  ) {
    this.iceCandidateHandlers.push(handler);
  }

  removeOnIceCandidateHandler(
    handler: (
      mediaType: MediaType,
      candidate: string,
      sdpMid: string,
      sdpMLineIndex: string,
      conferenceUserId?: string
    ) => void
  ) {
    this.iceCandidateHandlers = this.iceCandidateHandlers.filter(
      (h) => h !== handler
    );
  }

  onConsumeAudioResponse(
    handler: (conferenceUserId: string, sdpAnswer: string) => void
  ) {
    this.consumeAudioRepsponseHandlers.push(handler);
  }

  removeConsumeAudioResponseHandler(
    handler: (conferenceUserId: string, sdpAnswer: string) => void
  ) {
    this.consumeAudioRepsponseHandlers =
      this.consumeAudioRepsponseHandlers.filter((h) => h !== handler);
  }

  onSessionEnded(handler: (reason: string) => void) {
    this.sessionEndedHandlers.push(handler);
  }

  onInitialElements(handler: (svgs: string[]) => void) {
    this.initialElementHandlers.push(handler);
  }

  onAddElement(handler: (id: string, svg: string) => void) {
    this.addElementHandlers.push(handler);
  }

  onUpdateElement(handler: (id: string, svg: string) => void) {
    this.updateElementHandlers.push(handler);
  }

  onRemoveElement(handler: (id: string) => void) {
    this.removeElementHandlers.push(handler);
  }

  onClearElements(handler: () => void) {
    this.clearElementsHandlers.push(handler);
  }

  onBroadcastStarted(
    handler: (id: string, name: string, telestrationId: string) => void
  ) {
    this.broadcastStartedHandlers.push(handler);
  }

  onBroadcastEnded(handler: (id: string) => void) {
    this.broadcastEndedHandlers.push(handler);
  }

  onTelestrationStarted(handler: (id: string, telestrationId: string) => void) {
    this.telestrationStartedHandlers.push(handler);
  }

  onTelestrationEnded(handler: (id: string) => void) {
    this.telestrationEndedHandlers.push(handler);
  }

  onTelestrationChanged(handler: (id: string, telestrationId: string) => void) {
    this.telestrationChangedHandlers.push(handler);
  }

  onThumbnail(handler: (id: string, data: string) => void) {
    this.thumbnailHandlers.push(handler);
  }

  onUserJoined(
    handler: (
      userId: string,
      self: boolean,
      username: string,
      userType: UserType,
      hasAudio: boolean,
      speaker: boolean,
      microphone: boolean
    ) => void
  ) {
    this.conferenceUserJoinedHandlers.push(handler);
  }

  onUserLeft(handler: (userId: string, self: boolean) => void) {
    this.conferenceUserLeftHandlers.push(handler);
  }

  onUserStateChanged(
    handler: (
      userId: string,
      self: boolean,
      hasAudio: boolean,
      speaker: boolean,
      microphone: boolean
    ) => void
  ) {
    this.conferenceUserStateChangedHandlers.push(handler);
  }

  onRoomState(handler: (users: UserState[]) => void) {
    this.roomStateHandlers.push(handler);
  }

  sendClientRequest(
    sessionId: string,
    inviteId: string,
    videoSdpOffer: string,
    audioSdpOffer: string
  ) {
    this.sendMessage("Client", {
      sessionId,
      inviteId,
      accessToken: this.authToken,
      sdpOffers: {
        video: videoSdpOffer,
        audio: audioSdpOffer,
      },
    });
  }

  sendIceCandidate(
    mediaType: MediaType,
    candidate: string,
    sdpMid: string,
    sdpMLineIndex: string,
    conferenceUserId?: string
  ) {
    this.sendMessage("IceCandidate", {
      mediaType,
      candidate: {
        candidate,
        sdpMid,
        sdpMLineIndex,
      },
      conferenceUserId,
    });
  }

  consumeAudio(conferenceUserId: string, sdpOffer: string) {
    this.sendMessage("ConsumeAudio", {
      conferenceUserId,
      sdpOffer,
    });
  }

  stopConsumeAudio(conferenceUserId: string) {
    this.sendMessage("StopConsumeAudio", {
      conferenceUserId,
    });
  }

  switchBroadcast(broadcastId: string) {
    this.sendMessage("SwitchBroadcast", {
      broadcastId,
    });
  }

  getRoomState() {
    this.sendMessage("GetRoomState", {});
  }

  getElements() {
    this.sendMessage("GetElements", {});
  }

  addElement(id: string, svg: string) {
    console.log("sending add", id, svg);

    this.sendMessage("AddElement", {
      elementId: id,
      elementSvg: svg,
    });
  }

  updateElement(id: string, svg: string) {
    console.log("sending update", id, svg);

    this.sendMessage("UpdateElement", {
      elementId: id,
      elementSvg: svg,
    });
  }

  removeElement(id: string) {
    console.log("sending remove", id);

    this.sendMessage("RemoveElement", {
      elementId: id,
    });
  }

  clearElements() {
    this.sendMessage("ClearElements", {});
  }

  holdStream() {
    this.sendMessage("HoldStream", {});
  }

  resumeStream() {
    this.sendMessage("ResumeStream", {});
  }

  showGrid() {
    this.sendMessage("ShowGrid", {});
  }

  hideGrid() {
    this.sendMessage("HideGrid", {});
  }

  updateConferenceState(speakerActive: boolean, micActive: boolean) {
    this.sendMessage("ConferenceUserState", {
      speakerActive,
      micActive,
    });
  }

  private sendMessage(
    id: NrasMessageId,
    data: Record<string, unknown>,
    waitForClientResponse = true
  ) {
    if (waitForClientResponse && !this.gotClientResponse && id !== "Client") {
      console.log("Queueing message until ClientResponse", id);
      this.onClientResponseBacklog.push(JSON.stringify({ id, ...data }));
    } else {
      if (this.ws.readyState !== WebSocket.OPEN) {
        console.log("Queueing message until connect", id);
        this.onOpenBacklog.push(JSON.stringify({ id, ...data }));
      } else {
        console.log("Sending message", id);
        this.ws.send(JSON.stringify({ id, ...data }));
      }
    }
  }

  private handle(msg: Record<string, any>) {
    // console.log("Message", msg);
    switch (msg.id) {
      case "Ping":
        this.sendMessage("Pong", {}, false);
        break;

      case "ClientResponse":
        // console.log("ClientResponse", this.clientResponseHandlers);
        if (msg.response === "accepted") {
          this.gotClientResponse = true;
          this.clientResponseHandlers.forEach((h) => h(msg.sdpAnswers));

          const backlog = this.onClientResponseBacklog.reverse();
          this.onClientResponseBacklog = [];
          while (backlog.length > 0) {
            this.ws.send(backlog.pop()!);
          }
        } else {
          this.errorHandlers.forEach((h) => h(msg.message));
        }
        break;

      case "IceCandidate":
        this.iceCandidateHandlers.forEach((h) =>
          h(
            msg.mediaType,
            msg.candidate.candidate,
            msg.candidate.sdpMid,
            msg.candidate.sdpMLineIndex,
            msg.conferenceUserId
          )
        );
        break;

      case "ConsumeAudioResponse":
        this.consumeAudioRepsponseHandlers.forEach((h) => {
          if (msg.sdpAnswer) {
            h(msg.conferenceUserId, msg.sdpAnswer);
          } else {
            console.error(
              `No SDP answer in ConsumeAudioResponse for ${msg.conferenceUserId}`,
              msg.message
            );
          }
        });
        break;

      case "SessionEnded":
        this.sessionEndedHandlers.forEach((h) => h(msg.reason));
        break;

      case "InitialElements":
        this.initialElementHandlers.forEach((h) =>
          h(msg.elements.map((element: SvgElement) => element.elementSvg))
        );
        break;

      case "AddElement":
        this.addElementHandlers.forEach((h) =>
          h(msg.elementId, msg.elementSvg)
        );
        break;

      case "UpdateElement":
        this.updateElementHandlers.forEach((h) =>
          h(msg.elementId, msg.elementSvg)
        );
        break;

      case "RemoveElement":
        this.removeElementHandlers.forEach((h) => h(msg.elementId));
        break;

      case "ClearElements":
        this.clearElementsHandlers.forEach((h) => h());
        break;

      case "ConferenceUserJoined":
        this.conferenceUserJoinedHandlers.forEach((h) =>
          h(
            msg.userId,
            msg.self,
            msg.username,
            msg.userType,
            msg.hasAudio,
            msg.speakerActive,
            msg.microphoneActive
          )
        );
        break;

      case "ConferenceUserLeft":
        this.conferenceUserLeftHandlers.forEach((h) => h(msg.userId, msg.self));
        break;

      case "ConferenceUserStateChanged":
        this.conferenceUserStateChangedHandlers.forEach((h) =>
          h(
            msg.userId,
            msg.self,
            msg.hasAudio,
            msg.speakerActive,
            msg.microphoneActive
          )
        );
        break;

      case "BroadcastStarted":
        this.broadcastStartedHandlers.forEach((h) =>
          h(msg.broadcastId, msg.broadcastName, msg.telestrationId)
        );
        break;

      case "BroadcastEnded":
        this.broadcastEndedHandlers.forEach((h) => h(msg.broadcastId));
        break;

      case "TelestrationStarted":
        this.telestrationStartedHandlers.forEach((h) =>
          h(msg.broadcastId, msg.telestrationId)
        );
        break;

      case "TelestrationEnded":
        this.telestrationEndedHandlers.forEach((h) => h(msg.broadcastId));
        break;

      case "TelestrationChanged":
        this.telestrationChangedHandlers.forEach((h) =>
          h(msg.broadcastId, msg.telestrationId)
        );
        break;

      case "Thumbnail":
        this.thumbnailHandlers.forEach((h) => h(msg.broadcastId, msg.data));
        break;

      case "RoomState":
        this.roomStateHandlers.forEach((h) => h(msg.roomState.users));
        break;

      default:
        console.warn(`Unhandled message type from NRAS Async API: ${msg.id}`);
        break;
    }
  }
}
